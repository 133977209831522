<script lang="ts" setup>
import useCpBem from '~/composables/useCpBem';
import CpText from '~/components/cp/CpText/CpText.vue';

defineProps<{
    picture: string
    link: string
    label: string
}>();

const {b, e} = useCpBem('cp-card-shopping-tools-home');
</script>
<template>
    <NuxtLink :class="b" :href="link">
        <v-img :class="e`image`" :src="picture" />
        <CpText variant="body-regular" type="primary">
            {{ label }}
        </CpText>
    </NuxtLink>
</template>
<style scoped lang="scss">
@import "assets/style/global.scss";
.cp-card-shopping-tools-home{
    min-width: 138px;
    height: 104px;
    border: 1px solid var(--color-neutral-extra-light);
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    gap: var(--cp-gutter-small);
    padding: var(--cp-gutter-small);
    align-items: center;
    text-decoration: none;
    border-radius: 4px;
    text-align: center;
    &__image {
      width: 100%;
      max-height: 48px;
    }
}
</style>
