<script setup lang="ts">
  import type {IArticlesWidget} from '~/utils/services/api';

  const props = defineProps<{
      widget: IArticlesWidget
      removeBottomPadding?: boolean
  }>();
  const {data} = useAFCatalogArticles(
    props.widget.articles, false, props.widget.categoryId, undefined, {lazy: true, server: false},
  );
</script>

<template>
    <CpSectionHomeRecommendedCategory v-if="data"
        :articles="data.articles"
        :title="widget.title"
        :cat-id="widget.categoryId"
        :remove-bottom-padding="removeBottomPadding"
    />
</template>
