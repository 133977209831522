<script setup lang="ts">
  import type {IBannerWidget} from '~/utils/services/api';
  import useCpBem from '~/composables/useCpBem';
  import type {IBannerPlace} from '~/types';

  const props = defineProps<{
    widget: IBannerWidget
    applyAspectRatio?: boolean
    data: IBannerPlace[] | null
    priority?: boolean
    index?: number
  }>();

  const dataBanner = computed(() => {
    if (props.data !== null) {
      const placeId = props.widget.slot === 'home-main' ? 'home-main-app' : props.widget.slot;
      const bannerPlace = props.data.find((bannerPlace: any) => bannerPlace.place === placeId);
      if (!bannerPlace) {
        // I noticed that sometimes banner-find doesn't have the required data.
        // So I added this condition to avoid errors and not showing the skeleton without data.
        console.error('Banner not handled ', placeId);
        return 'error';
      }
      return bannerPlace;
    }
    return null;
  });

  const {bm} = useCpBem('cp-home-banner');

  const aspectRatio = props.index === 0 ? 'aspect-ratio: 413 / 163' : 'aspect-ratio: 350 / 163';

</script>

<template>
    <VLazy v-if="typeof index === 'number' && index > 0" transition="fade-transition" :options="{ rootMargin: '-100px' }">
        <div :class="bm({ withStroke: true })">
            <CpSlideshow v-if="dataBanner && dataBanner !== 'error'"
                :place="dataBanner"
                :apply-aspect-ratio="applyAspectRatio"
                :priority="priority"
                :aspect-ratio="aspectRatio"
            />
            <LazyCpSkeleton v-else
                kind="banner"
                effect="fade"
                class="ma-4"
                :apply-aspect-ratio="applyAspectRatio"
            />
        </div>
    </VLazy>
    <div v-else>
        <CpSlideshow v-if="dataBanner && dataBanner !== 'error'"
            :place="dataBanner"
            :apply-aspect-ratio="applyAspectRatio"
            :priority="priority"
            :aspect-ratio="aspectRatio"
        />
        <LazyCpSkeleton v-else
            kind="banner"
            effect="fade"
            class="ma-4"
            :apply-aspect-ratio="applyAspectRatio"
        />
    </div>
</template>

<style scoped lang="scss">
  .cp-home-banner {
    width: 100%;
    overflow: hidden;
    margin-bottom: $cp-gutter;
    &--with-stroke {
      margin-top: $cp-gutter;
      border-top: $cp-border-standard;
    }
  }
</style>
