import type {IHomeSchema} from '~/utils/services/api';
import {getHome} from '~/utils/services/api';
import {QUERY_KEYS} from '~/utils/services/keys';
import type {AsyncData, AsyncDataOptions, NuxtError} from '#app';

export const useHome = (options: AsyncDataOptions<IHomeSchema, any> = {}) => {
  const clientCacheOptions = useClientCache();
  return useCachedAsyncData(QUERY_KEYS.home, () => {
    return getHome();
  },
    {
      ...clientCacheOptions,
      ...options,
      transform: (data) => {
        const transformFromOptions = options.transform ? options.transform(data) : data;
        return clientCacheOptions.transform(transformFromOptions);
      },
    },
  {cacheKey: QUERY_KEYS.home, cacheTags: [QUERY_KEYS.home], cacheExpires: 3600 * 3}) as AsyncData<IHomeSchema, NuxtError<unknown> | null>;
};

/**
 * Iterates over all articles inside Home response and then remove duplicates. */
const getHomeArticles = (data: IHomeSchema) => {
  let articleIds: string[] = [];
  data.widgets.forEach((widget) => {
    if ('articles' in widget) {
      articleIds = [...articleIds, ...widget.articles];
    }
  });
  return [...new Set(articleIds)];
};

export const useFullHome = () => {
  return useAsyncData<IHomeSchema>(QUERY_KEYS.home, async () => {
    const homeData = await getHome();
    const allArticles = getHomeArticles(homeData);
    await useAFCatalogArticles(allArticles);
    return homeData;
  });
};
