<script lang="ts" setup>
  import {ref} from 'vue';
  import {useI18n} from 'vue-i18n';
  import useCpBem from '~/composables/useCpBem';
  import CpSectionHeader from '~/components/cp/CpSection/CpSectionHeader/CpSectionHeader.vue';
  import CpSectionStrip from '~/components/cp/CpSection/CpSectionStrip/CpSectionStrip.vue';
  import type {ICpArticle} from '~/types';
  import CpModalDialog from '~/components/cp/CpModal/CpModalDialog/CpModalDialog.vue';

  defineProps<{
    articles: Array<ICpArticle>
    title: string
    catId: string
    removeBottomPadding?: boolean
  }>();
  defineEmits<{
    (e: 'clickButton'): void
  }>();
  const {t} = useI18n();
  const {b} = useCpBem('cp-section-home-recommended-category');
  const showModalDialog = ref<boolean>(false);
</script>
<template>
    <div :class="b">
        <CpSectionHeader
            :title="title"
            type="h2"
            button="info"
            show-top-border
            @header-action="showModalDialog = true"
        />
        <!--Grid view -->
        <CpSectionStrip
            data-testid="recommended-category-cards"
            button
            :items-to-show="articles"
            kind="cta6"
            card-type="home"
            :label="t('go')+ title.replace('Lo más relevante en ', ' ')"
            :remove-bottom-padding="removeBottomPadding"
            @button-press="navigateTo(useCpNavigateToParams('catalog', articles[0].category.link))"
        />
        <CpModalDialog
            v-model:show="showModalDialog"
            :title="t('modalHeader')"
            :body="t('modalBody')"
            :label-first-button="t('goBack')"
            :subheading="t('modalTitle')"
            @first-button="showModalDialog = false"
        />
    </div>
</template>
<style scoped lang="scss">
  @import "assets/style/global.scss";
  .cp-section-home-recommended-category {
    &__cards {
      display: flex;
      flex-wrap: wrap;
      gap: $cp-gutter;
      padding: $cp-gutter;
    }
    &__button {
      width: 100%;
      padding-left: $cp-gutter;
      padding-right: $cp-gutter;
      padding-bottom: $cp-gutter;
    }
  }
</style>
<i18n locale="es-US" lang="yaml">
  go: 'Ir a'
  goBack: 'Regresar'
  modalHeader: 'Aviso'
  modalTitle: 'Lo más relevante de la categoría'
  modalBody: 'Descubre una selección de los productos con mayor relevancia basada en las opiniones de clientes, disponibilidad, ventas, promociones, entre otros datos.'
</i18n>
