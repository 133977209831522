<script setup lang="ts">
  import {useBanners} from '~/composables/queries/banner';
  import {useHome} from '~/composables/queries/home';
  import {generateHome1JsonLd, generateHome2JsonLd} from '~/utils/jsonld';
  import speedkitHydrate from '#speedkit/hydrate';
  import {LazyHydrationWrapper} from 'vue3-lazy-hydration';
  import {isAppleDeviceAndRunningSarafi} from '~/utils';
  import CpSectionShoppingToolsHome from '~/components/cp/CpSectionShoppingToolsHome/CpSectionShoppingToolsHome.vue';

  const HomeOffers = speedkitHydrate(() => import('~/components/features/Home/HomeOffers.vue'));
  const HomeBrands = speedkitHydrate(() => import('~/components/features/Home/HomeBrands.vue'));
  const HomeCategories = speedkitHydrate(() => import('~/components/features/Home/HomeCategories.vue'));
  const CpText = speedkitHydrate(() => import('~/components/cp/CpText/CpText.vue'));

  definePageMeta({
    layout: 'home-layout',
    name: 'home',
  });
  useSeoMeta({
    title: 'Cyberpuerta.mx: Hardware, Computadoras, Laptops & Más',
    description: 'Venta en línea de Hardware, Computadoras, Laptops, Impresoras, Monitores y más.\n' +
    '✓Precios accesibles ✓Excelente servicio \n' +
    '✓55,000 productos ✓15 años en el mercado ✓500,000+ clientes"\n',
    ogUrl: 'https://www.cyberpuerta.mx',
    ogTitle: 'Cyberpuerta.mx: Hardware, Computadoras, Laptops & Más',
    ogDescription: 'Venta en línea de Hardware, Computadoras, Laptops, Impresoras, Monitores y más.\n' +
    '✓Precios accesibles ✓Excelente servicio \n' +
    '✓55,000 productos ✓15 años en el mercado ✓500,000+ clientes"\n',
    ogImage: 'https://www.cyberpuerta.mx/out/cyberpuertaV5/img/logo2.png',
    ogImageWidth: '381',
    ogImageHeight: '82',
  });
  const headers = useRequestHeaders(['user-agent']);
  const {b, e} = useCpBem('cp-home');
  const {data: homeResponse, pending: showSkeleton} = useHome({
    transform: (data) => {
      const widgets = data.widgets.map((widget) => {
        /**
          * In home we just show 6 articles from offers and 6 categories
          * so we don't need to get all of them
        */
        if (widget.type === 'offers') {
          const articles = widget.articles.slice(0, 6);
          return {...widget, articles};
        }
        if (widget.type === 'categories') {
          const categories = widget.categories.splice(0, 6);
          return {...widget, categories};
        }
        return widget;
      });
      return {widgets};
    },
  });
  const {data: banners} = await useBanners({
    placeId: 'home-main',
    controllerId: 'start',
  }, true);

  const successfullyRegisteredModal = ref(useState('showRegisteredModalFrom').value === 'menu');
  const {t} = useI18n();

  const goToKnowMoreAboutCp = () => {
    navigateTo('https://www.cyberpuerta.mx/Bienvenido-a-Cyberpuerta', {external: true});
  };

  onUnmounted(() => {
    clearNuxtState('showRegisteredModalFrom');
  });
  useHead({
    script: [
      {
        type: 'application/ld+json',
        children: () => generateHome1JsonLd(),
        key: 'organization-schema',
      },
      {
        type: 'application/ld+json',
        children: () => generateHome2JsonLd(),
        key: 'website-schema',
      },
    ],
    link: [
      {rel: 'canonical', href: 'https://www.cyberpuerta.mx/'},
      // @ts-expect-error values are ok and tested IT-21380
      ...(banners.value ?
        [{
                  rel: 'preload',
                  as: 'image',
                  href: banners.value[0].banners[0].pictureUrl,
                  fetchpriority: 'high',
                }] :
        []
      ),
    ],
    meta: [{
      name: 'apple-itunes-app',
      content: 'app-id=1636030641',
    }],
  });
</script>

<template>
    <div :class="b">
        <!-- hidden h1 tag used for SEO -->
        <CpText variant="heading-h1" :class="e`seo-title`">
            Cyberpuerta.mx
        </CpText>
        <LazyCpSkeletonPageHome v-if="showSkeleton" />
        <div v-else>
            <CpNotificationBannerPromoteApp v-if="!isAppleDeviceAndRunningSarafi(headers['user-agent'])" />
            <div v-for="(item, index) in homeResponse?.widgets" :key="item.type" :class="e('widget')">
                <HomeOffers v-if="item.type === 'offers'" :widget="item" data-testid="featured-products" />
                <HomeBanner
                    v-if="item.type === 'banner' && index === 0"
                    :widget="item"
                    data-testid="home-banner-slider"
                    :apply-aspect-ratio="index === 0"
                    :priority="index === 0"
                    :index="index"
                    :data="banners"
                    critical
                />
                <LazyHydrationWrapper v-if="item.type === 'banner'" :when-visible="{ rootMargin: '10px' }">
                    <HomeBanner
                        v-if="item.type === 'banner' && index !== 0"
                        :widget="item"
                        data-testid="home-banner-slider"
                        :apply-aspect-ratio="index === 0"
                        :priority="index === 0"
                        :index="index"
                        :data="banners"
                    />
                </LazyHydrationWrapper>
                <HomeBrands v-if="item.type === 'brands'" :widget="item" />
                <CpSectionShoppingToolsHome v-if="item.type === 'shopping-tools'" :widget="item" :cards="item.tools" />
                <HomeCategories v-if="item.type === 'categories'" :widget="item" data-testid="featured-categories" />
                <LazyHydrationWrapper v-if="item.type === 'articles'" :when-idle="true">
                    <HomeArticles v-if="item.type === 'articles'" :widget="item" data-testid="recommended-categories" :remove-bottom-padding="index === (homeResponse?.widgets.length ?? 1) - 3" />
                </LazyHydrationWrapper>
                <ClientOnly>
                    <LazyHydrationWrapper v-if="item.type === 'lastSeen'" :when-visible="{ rootMargin: '10px'}">
                        <LazyHomeViewHistory v-if="item.type === 'lastSeen'" :widget="item" />
                    </LazyHydrationWrapper>
                    <LazyHydrationWrapper v-if="item.type === 'recommendations'" :when-visible="{ rootMargin: '10px'}">
                        <LazyHomeRecommendations v-if="item.type === 'recommendations'" :widget="item" />
                    </LazyHydrationWrapper>
                </ClientOnly>
                <LazyHydrationWrapper v-if="item.type === 'alert'" :when-visible="{ rootMargin: '10px'}">
                    <LazyCpAlert
                        v-if="item.type === 'alert'"
                        :kind="item.variant === 'info' ? 'info' : 'warning'"
                        :close-icon="false"
                        :action="false"
                    >
                        {{ item.content }}
                    </LazyCpAlert>
                </LazyHydrationWrapper>
                <CpText
                    v-if="item.type === 'text'"
                    variant="body-regular"
                >
                    {{ item.content }}
                </CpText>
                <LazyHydrationWrapper v-if="item.type === 'divider'" :when-visible="{ rootMargin: '0px'}">
                    <LazyCpDivider v-if="item.type === 'divider'" />
                </LazyHydrationWrapper>
            </div>
            <LazyHydrationWrapper :when-visible="{ rootMargin: '0px'}">
                <CpSectionHomeAbout />
            </LazyHydrationWrapper>
            <LazyCpModalDialog
                v-if="successfullyRegisteredModal"
                v-model:show="successfullyRegisteredModal"
                :title="t('successfulAction')"
                :subheading="t('weAreGladToSeeYou')"
                :body="t('sign-up-desc')"
                :label-first-button="t('buttonMainPage')"
                :label-second-button="t('buttonKnowMore')"
                type-first-button="primary"
                type-second-button="primary"
                kind="success"
                use-confetti-animation
                use-bold-text
                @first-button="successfullyRegisteredModal = false "
                @second-button="goToKnowMoreAboutCp()"
                @update:show="clearNuxtState('showRegisteredModalFrom')"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
.cp-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__seo-title {
    display: block;
    overflow: hidden;
    padding: 0;
    position: absolute;
    z-index: -1;
  }
}
</style>

<i18n locale="es-US" lang="yaml">
  successfulAction: 'Acción exitosa'
  sign-up-desc: 'Tu cuenta ha sido creada exitosamente. Descarga nuestra aplicación, inicia sesión con la cuenta que acabas de crear y te otorgaremos un *saldo de regalo de $100.00*. Este se aplicará automáticamente en tu próxima compra con un valor de productos mínimo de $700.00.'
  buttonMainPage: 'Ir a la página principal'
  buttonKnowMore: 'Conocer más sobre Cyberpuerta'
  weAreGladToSeeYou: '¡Nos alegra verte!'
</i18n>
