<script setup lang="ts">
  import {useI18n} from 'vue-i18n';
  import useCpBem from '~/composables/useCpBem';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import CpSectionHeader from '~/components/cp/CpSection/CpSectionHeader/CpSectionHeader.vue';

  const {b, e} = useCpBem('cp-section-home-about');
  const {t} = useI18n();
</script>
<template>
    <div :class="b">
        <CpSectionHeader type="h2" :title="t('saleOfComputers')" show-top-border />
        <div :class="e`container`">
            <div :class="e`sub-container`">
                <div :class="e`multi-text-container`">
                    <CpText variant="body-regular">
                        {{ t('greatVarietyOfProducts') }}
                    </CpText>
                    <nuxt-link :class="e`ref`" href="https://www.cyberpuerta.mx/Computo-Hardware/">
                        {{ t('hardware') }}
                    </nuxt-link>
                    <CpText variant="body-regular">
                        {{ ' ' }}
                    </CpText>
                    <nuxt-link :class="e`ref`" href="https://www.cyberpuerta.mx/Computadoras/PC-s-de-Escritorio/">
                        {{ t('computers') }}
                    </nuxt-link>
                    <CpText variant="body-regular">
                        {{ ' ' }}
                    </CpText>
                    <nuxt-link :class="e`ref`" href="https://www.cyberpuerta.mx/Computadoras/Laptops/">
                        {{ t('laptops') }}
                    </nuxt-link>
                    <CpText variant="body-regular">
                        {{ ' ' }}
                    </CpText>
                    <nuxt-link :class="e`ref`" href="https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresoras/">
                        {{ t('printers') }}
                    </nuxt-link>
                    <CpText variant="body-regular">
                        {{ ' ' }}
                    </CpText>
                    <nuxt-link :class="e`ref`" href="https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Monitores/">
                        {{ t('monitors') }}
                    </nuxt-link>
                    <CpText variant="body-regular">
                        {{ ' ' }}
                    </CpText>
                    <nuxt-link :class="e`ref`" href="https://www.cyberpuerta.mx/Computo-Hardware/Componentes/">
                        {{ t('components') }}
                    </nuxt-link>
                    <CpText variant="body-regular">
                        {{ t('andMore') }}
                    </CpText>
                </div>
                <CpText variant="body-regular">
                    {{ t('ourQualityAndSpeedserviceIsNotLimited') }}
                </CpText>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
  .cp-section-home-about {
    display: flex;
    flex-direction: column;
    &__container {
      padding: $cp-gutter;
    }
    &__sub-container {
      padding: $cp-gutter-small;
      display: flex;
      flex-direction: column;
      gap: $cp-gutter;
      border-radius: $cp-gutter-small;
      border: $cp-border-standard;
    }
    &__multi-text-container {
      line-height: 0;
    }
    &__ref {
      color: var(--color-primary);
      font-family: var(--cp-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-body-bold-size);
      line-height: var(--cp-body-bold-line-height);
    }
  }
</style>
<i18n lang="yaml" locale="es-US">
  saleOfComputers: 'Venta de Computadoras, Hardware, Laptops, Monitores y más'
  greatVarietyOfProducts: 'Gran variedad de productos, disponibilidad, precios bajos y seguridad al momento de compra, son algunos de los beneficios que Cyberpuerta trae para ti gracias a nuestros 15 años en el mercado. A través de nuestro portal podrás encontrar más de 55,000 productos como '
  hardware: 'hardware,'
  computers: 'computadoras,'
  laptops: 'laptops,'
  printers: 'impresoras,'
  monitors: 'monitores,'
  components: 'componentes'
  andMore: ' y más, sin tener que salir de tu hogar. Además, nuestro envío asegurado garantiza la tranquilidad de recibir tu mercancía sin preocupaciones.'
  ourQualityAndSpeedserviceIsNotLimited: "Nuestro servicio de calidad y rapidez no se limita solo a los consumidores finales, sino también a las empresas que buscan soluciones rápidas y efectivas. Si necesitas de atención personalizada durante la compra o al momento de realizar alguna cotización, con gusto te atenderemos a través de nuestro chat, por email en info{'@'}cyberpuerta.mx o vía telefónica en nuestras líneas de servicio al cliente."
</i18n>
