import {homePaths} from '~/utils/services/paths';
import {$cpFetch} from '~/composables/useCpFetch';

export interface IAlertWidget {
  content: string
  type: 'alert'
  variant: 'info' | 'warning'
}
export interface ITextWidget {
  content: string
  type: 'text'
  variant: string
}
export interface IDividerWidget {
  type: 'divider'
}
export interface IGridWidget {
  content: Array<[ITextWidget, ITextWidget]>
  type: 'grid'
}
export interface IOffersWidget {
  articles: string[]
  id: string
  saleData: {
    maxDiscount: string
    maxSalePercent: string
  }
  title: string
  type: 'offers'
}
export interface IBrandsWidget {
  brands: string[]
  title: string
  type: 'brands'
}
export interface IBannerWidget {
  slot: string
  type: 'banner'
}
export interface ICategoriesWidget {
  categories: string[]
  title: string
  type: 'categories'
}
export interface IArticlesWidget {
  articles: string[]
  categoryId: string
  title: string
  type: 'articles'
}
export interface IRecommendationsWidget {
  shop: 'ab' | 'cp';
  slot: string;
  slotName: string;
  type: 'recommendations';
  sku?: string;
}
export interface ILastSeenWidget {
  articles: string[]
  title: string
  type: 'lastSeen'
}
export interface ITool {
  id: string
  title: string
  description: string
  picture: string
  picture_pdp: string
  button_text: string
  category_id?: string
  link: string
}
export interface IShoppingTools {
  type: 'shopping-tools'
  title: string
  tools: Array<ITool>
}

export type IWidgetType =
  | IOffersWidget
  | IBrandsWidget
  | IBannerWidget
  | ICategoriesWidget
  | IArticlesWidget
  | IRecommendationsWidget
  | ILastSeenWidget
  | IAlertWidget
  | ITextWidget
  | IDividerWidget
  | IGridWidget
  | IShoppingTools

export interface IHomeSchema {
  widgets: IWidgetType[]
}

export const getHome = () => {
  return $cpFetch<IHomeSchema>(homePaths.home);
};
